import { initializeApp } from 'firebase/app'
import { getFunctions } from 'firebase/functions';
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';

export const firebaseApp = initializeApp({
  apiKey: "AIzaSyC1hXWgUNQg9OqsqDURhn7OZXfd1EI-R_8",
  authDomain: "reemedical-project.firebaseapp.com",
  databaseURL: "https://reemedical-project-default-rtdb.firebaseio.com",
  projectId: "reemedical-project",
  storageBucket: "reemedical-project.appspot.com",
  messagingSenderId: "840967454718",
  appId: "1:840967454718:web:a9816ed6a222b2b6b50aaf",
  measurementId: "G-YKNJMB3CRR"
});

export const firebaseAppCheck = initializeAppCheck(firebaseApp, {
  provider: new ReCaptchaV3Provider("6LdE0SIqAAAAAMCXXaEYkTvp5joqRB9002oz4MQs"),
  isTokenAutoRefreshEnabled: true,
});
export const firebaseFunctions = getFunctions(firebaseApp);