import { createWebHistory, createRouter } from 'vue-router'

import ReferralRock from '../components/ReferralRock.vue'
import ReferralRockLeaderboard from '../components/ReferralRockLeaderboard.vue'

const routes = [
  { path: '/', component: ReferralRock },
  { path: '/leaderboard', component: ReferralRockLeaderboard },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;