<template>
  <div class="container">
    <div v-if="loading">
      <div class="spinner-border" role="status">
        <span class="sr-only"></span>
      </div>
    </div>
    <div v-else class="mx-auto" style="width: 300px;">
      <h3>Registered Today</h3>
      <table class="table table-bordered table-striped text-start">
        <thead>
          <tr>
            <th scope="col">Name</th>
            <th scope="col">Count</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(value, index) in leaderboard" :key="index">
            <td>{{ index }}</td>
            <td>{{ value }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import { defineComponent, onMounted, ref } from "vue";
import { firebaseFunctions } from "@/firebase";
import { httpsCallable } from "firebase/functions";

export default defineComponent({
  name: "referral-rock-leaderboard",
  components: {},
  setup() {
    const rrAPI = httpsCallable(firebaseFunctions, "referralRock", { limitedUseAppCheckTokens: true });

    const loading = ref(true);
    const leaderboard = ref({});
    const todayLeaderboard = ref("");

    const reloadTable = (firstTime) => {
      rrAPI({ action: "referralRegisteredLeaderboard", payload: todayLeaderboard.value }).then((result) => {
        const referrals = result.data;
        console.log(leaderboard.value);
        // Parse them
        for (var i = 0; i < referrals.data.length; i++) {
          const referral = referrals.data[i];
          const owner = referral.Owner.name;
          console.log(owner);

          if (leaderboard.value[owner] === undefined) {
            leaderboard.value[owner] = 0;
          }
          leaderboard.value[owner] += 1;
        }
        leaderboard.value = Object.entries(leaderboard.value)
          .sort(([,a],[,b]) => b-a)
          .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
        loading.value = false;
      });
      // Only on the first load
      if (firstTime) {
        setInterval(() => {
          loading.value = true;
          reloadTable(false);  
        }, (5 * 60 * 1000));      
      }
    }

    onMounted(() => {
      const now = new Date();
      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, '0');
      const day = String(now.getDate()).padStart(2, '0');
  
      // Get timezone offset in minutes and convert to hours and minutes
      const timezoneOffset = -now.getTimezoneOffset();
      const timezoneHours = String(Math.floor(Math.abs(timezoneOffset) / 60)).padStart(2, '0');
      const timezoneMinutes = String(Math.abs(timezoneOffset) % 60).padStart(2, '0');
      const timezoneSign = timezoneOffset >= 0 ? '+' : '-';

      todayLeaderboard.value = `${year}-${month}-${day}T00:00:00${timezoneSign}${timezoneHours}:${timezoneMinutes}`;
      reloadTable(true);
    })

    return {
      leaderboard,
      loading,
    };
  },
});

</script>