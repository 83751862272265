<template>
  <div class="container text-start px-5">
    <div class="row mb-3">
      <div class="col-3">
        <label for="inputFirstName" class="col-form-label">
          First Name<span class="text-danger">*</span>
        </label>
      </div>
      <div class="col">
        <input type="text" id="inputFirstName" 
          placeholder="example: John"
          class="form-control" aria-describedby="firstNameHelpInline" 
          v-model="formData.firstName"
          :disabled="successMessage.show">
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-3">
        <label for="inputLastName" class="col-form-label">
          Last Name<span class="text-danger">*</span>
        </label>
      </div>
      <div class="col">
        <input type="text" id="inputLastName" 
          placeholder="example: Smith"
          class="form-control" aria-describedby="lastNameHelpInline"
          v-model="formData.lastName"
          :disabled="successMessage.show">
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-3">
        <label for="inputEmail" class="col-form-label">
          Email<span class="text-danger">*</span>
        </label>
      </div>
      <div class="col">
        <input type="email" id="inputEmail" 
          placeholder="example: email@domain.com"
          class="form-control" aria-describedby="emailHelpInline"
          v-model="formData.email"
          :disabled="successMessage.show">
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-3">
        <label for="inputPhone" class="col-form-label">Phone</label>
      </div>
      <div class="col">
        <input type="number" id="inputPhone" 
          placeholder="example: 8085551234"
          class="form-control" aria-describedby="phoneHelpInline"
          v-model="formData.phone"
          :disabled="successMessage.show">
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-3">
        <label for="inputPayItFwd" class="col-form-label">
          Award Preference<span class="text-danger">*</span>
        </label>
      </div>
      <div class="col">
        <select id="payItForward" class="form-control" 
          v-model="formData.payItForward" 
          :disabled="successMessage.show">
          <option v-for="pif in payItForward" :key="pif.value" :value="pif.value">
            {{ pif.option }}
          </option>
        </select>
      </div>
    </div>
    <div class="row text-center" v-if="successMessage.show">
      <div class="col">
        <p>Referral Code: {{ successMessage.referralCode }}</p>
        <p>Referral Link: {{ successMessage.referralLink }}</p>
      </div>
    </div>
    <div class="row text-danger text-center" v-if="errorMessage.show">
      <div class="col">
        {{ errorMessage.message }}
      </div>
    </div>
    <div class="row text-end">
      <div class="col-3">&nbsp;</div>
      <div class="col">
        <button type="button" class="btn btn-primary" @click="createMe" :disabled="successMessage.show">
          <span class="indicator-label" v-if="!createInProgress">
            Create
          </span>
          <span class="indicator-progress" v-if="createInProgress">
            Please wait... <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
          </span>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref } from "vue";
import { firebaseFunctions } from "@/firebase";
import { httpsCallable } from "firebase/functions";
import { useRoute } from 'vue-router';

export default defineComponent({
  name: "referral-rock",
  components: {},
  setup() {
    const errorMessage = ref({
      'show': false,
      'message': null,
    });
    const successMessage = ref({
      'show': false,
      'referralCode': null,
      'referralLink': null,
    });
    const createInProgress = ref(false);
    const formData = ref({
      'firstName': null,
      'lastName': null,
      'email': null,
      'phone': null,
      'payItForward': null,
    });
    const payItForward = ref([
      { 'value': 100, 'option': "$100 Cash" },
      { 'value': -100, 'option': "$100 Discount" },
    ]);

    const route = useRoute();
    // Names
    if (route.query.name !== undefined) {
      const name = route.query.name.split(" ");
      formData.value.firstName = name[0];
      name.shift();
      formData.value.lastName = name.join(" ");
      // Email
      formData.value.email = route.query.email;
      // Phone
      formData.value.phone = route.query.phone;
    }

    const createMe = () => {
      errorMessage.value.show = false;
      errorMessage.value.message = null;
      // Validate
      if (formData.value.firstName !== '' && formData.value.lastName !== '' && 
          formData.value.email !== '' && formData.value.payItForward !== '' &&
          formData.value.firstName !== null && formData.value.lastName !== null && 
          formData.value.email !== null && formData.value.payItForward !== null) {
        createInProgress.value = true;
        const rrAPI = httpsCallable(firebaseFunctions, "referralRock", { limitedUseAppCheckTokens: true });
        rrAPI({ action: "addMember", payload: formData.value }).then((result) => {
          console.log(result.data.member);
          successMessage.value.show = true;
          if (result.data.member !== null) {
            successMessage.value.referralCode = result.data.member.referralCode;
            successMessage.value.referralLink = result.data.member.referralUrl;
            createInProgress.value = false;
          } else {
            if (result.data.message === "Email address is already being used by existing member") {
              rrAPI({ action: "searchMember", payload: formData.value.email }).then((response) => {
                if (response.data.members !== null && response.data.members.length > 0) {
                  let memberFound = false;
                  let memberId = "";
                  for (var i = 0;i < response.data.members.length;i++) {
                    const member = response.data.members[i];
                    if (member['email'] === formData.value.email) {
                      successMessage.value.referralCode = member.referralCode;
                      successMessage.value.referralLink = member.referralUrl;
                      memberFound = true;
                      memberId = member.id;
                    }
                  }
                  // Member Found
                  if (memberFound) {
                    rrAPI({ action: "updateMemberInCRM", payload: { 
                      "Email": formData.value.email,
                      "ReferralCode": successMessage.value.referralCode,
                    } }).then((updateResponse) => {
                      console.log(updateResponse);
                      // Only update if they chose discount
                      if (formData.value.payItForward < 0 && memberId != "") {
                        rrAPI({ action: "updateMember", payload: {
                          "member_id": memberId,
                          "payItForward": formData.value.payItForward,
                        } });
                      }
                    });
                  }
                }
                createInProgress.value = false;
              }).catch((err) => {
                errorMessage.value.show = true;
                errorMessage.value.message = err;  
                createInProgress.value = false;
              });
            } else {
              errorMessage.value.show = true;
              errorMessage.value.message = result.data.message;    
              createInProgress.value = false;
            }
          }
        }).catch((err) => {
          createInProgress.value = false;
          errorMessage.value.show = true;
          errorMessage.value.message = err;  
        });
      } else {
        errorMessage.value.show = true;
        errorMessage.value.message = "First Name, Last Name, Email and 'Award Preference' are required fields.";
      }
    }

    return {
      errorMessage,
      successMessage,
      createInProgress,
      formData,
      payItForward,
      createMe,
    };
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>